/** @jsx jsx */
import { jsx, Container } from "theme-ui";
import Logo from "components/logo";
import LogoImage from "assets/logo-text.svg";
import { DrawerProvider } from "../../contexts/drawer/drawer.provider";
import { FormattedMessage, Link } from "gatsby-plugin-intl";
import { useEffect, useState } from "react";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

export default function Header({ className, setMenuVisible }) {
  const [href, setHref] = useState(
    "https://apps.apple.com/app/couponise/id1554931849"
  );

  useEffect(() => {
    // Update the document title using the browser API
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      setHref(
        "https://play.google.com/store/apps/details?id=app.couponise.couponise_android"
      );
    }
  });

  const [showScrolling, setShowScrolling] = useState(false);
  const [showReveal, setShowReveal] = useState(false);

  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < 0) {
      setShowScrolling(true);
    } else {
      setShowScrolling(false);
    }
    if (currPos.y < -300) {
      setShowReveal(true);
    } else {
      setShowReveal(false);
    }
  });

  const isPartiallyActive = ({ isPartiallyCurrent }) => {
    if (typeof window !== 'undefined') {
      const pathName = window.location.pathname;
      if(pathName.includes('/blog/')){
        return { className: 'nav-link nav-link--active' };
      }
    }
    return { className: 'nav-link' };
  }

  return (
    <DrawerProvider>
      <header
        className={`site-header mobile-sticky-enable site-header--menu-right px-lg-10 dynamic-sticky-bg site-header--absolute site-header--sticky
        ${showScrolling ? "scrolling" : ""}
        ${showReveal ? "reveal-header " : ""}`}
        id="header"
      >
        <div className="container">
          <nav className="container navbar site-navbar offcanvas-active navbar-expand-lg px-0">
            <div className="brand-logo mt-md-0">
              <Logo src={LogoImage} />
            </div>
            <div className="collapse navbar-collapse">
              <div className="navbar-nav-wrapper">
                <ul className="navbar-nav main-menu d-none d-lg-flex">
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      activeClassName="nav-link--active"
                      to={"/features/"}
                    >
                      <FormattedMessage id="features" />
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      activeClassName="nav-link--active"
                      getProps={isPartiallyActive}
                      to={"/blog/loyalty-campaigns/"}
                    >
                      <FormattedMessage id="blog" />
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      activeClassName="nav-link--active"
                      to={"/faq/"}
                    >
                      <FormattedMessage id="faq" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="header-btn ml-auto ml-lg-0 d-xs-block">
              <a
                href={href}
                className="btn btn btn-couponise btn-sm rounded-pill"
                rel="noreferrer"
                target="_blank"
              >
                <FormattedMessage id="try_free" />
              </a>
            </div>
            <button className="hamburger-button ml-3 d-lg-none" role="button" aria-label="menu" onClick={() => setMenuVisible(true)}>
              <i className="fa fa-bars"></i>
            </button>
          </nav>
        </div>
      </header>
    </DrawerProvider>
  );
}
