/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useState } from 'react';
import Header from './header/header';
import Footer from './footer/footer';
import MobileMenu from './mobile-menu';

import "../assets/scss/bootstrap.scss";
import "../assets/scss/main.scss";
import "../../node_modules/slick-carousel/slick/slick.css";

import "../assets/fonts/fontawesome-5/webfonts/fa-brands-400.ttf";
import "../assets/fonts/fontawesome-5/webfonts/fa-regular-400.ttf";
import "../assets/fonts/fontawesome-5/webfonts/fa-solid-900.ttf";

import "../assets/fonts/fontawesome-5/css/all.css";

export default function Layout({ children }) {
  const [menuVisible, setMenuVisible] = useState(false);

  return (
    <React.Fragment>
      <Header setMenuVisible={setMenuVisible}  />
      <main
        sx={{
          variant: 'layout.main',
        }}
      >
        {children}
      </main>
      <Footer />
      <MobileMenu menuVisible={menuVisible} setMenuVisible={setMenuVisible} />
    </React.Fragment>
  );
}
