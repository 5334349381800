/** @jsx jsx */
import { jsx, Box, Container } from 'theme-ui';
import { IntlContextConsumer, changeLocale, FormattedMessage, Link } from "gatsby-plugin-intl"

const languageName = {
  de: "Deutsch",
  es: "Español",
  en: "English",
  it: "Italiano",
  hu: "Magyar",
  ru: "Русский",
  ro: "Română",
  ko: "한국어",
  pt: "Português",
  fr: "Français",
  sk: "Slovenský"
};

export default function Footer() {
  return (
    <footer
      sx={{
        variant: 'layout.footer',
        backgroundColor: '#fff',
      }}
    >
      <Container
        sx={{
          variant: 'layout.toolbar',
          borderTop: '1px solid',
          borderTopColor: 'border_color',
          justifyContent: ['center', null, null, 'space-between'],
          flexDirection: ['column-reverse', null, null, null, 'row'],
          paddingTop: [30, 40],
          paddingBottom: [30, 65],
        }}
      >
        <Box sx={styles.left}>
          <IntlContextConsumer>
            {({ languages, language: currentLocale }) => (
              <label sx={styles.language}>
                <select onChange={(event) => changeLocale(event.target.value)} value={currentLocale}>
                  {languages.slice().sort().map(language => (
                    <option key={language} value={language}>
                      {languageName[language]}
                    </option>
                  ))}
                </select>
              </label>
            )}
          </IntlContextConsumer>
        </Box>
        <Box sx={styles.right}>
          <a href="mailto:hello@couponise.app">
            <FormattedMessage id="contact_us" />
          </a>
          <Link to={'/terms-of-use/'}>
            <FormattedMessage id="terms_and_conditions" />
          </Link>
          <Link to={'/privacy-policy/'}>
            <FormattedMessage id="privacy" />
          </Link>
        </Box>
      </Container>
    </footer>
  );
}

const styles = {
  language: {
    position: 'relative',
    '&:before': {
      content: '""',
      width: 0,
      height: 0,
      borderLeft: '7px solid transparent',
      borderRight: '7px solid transparent',
      borderTop: '7px solid #757575',
      position: 'absolute',
      top: '18px',
      right: '8px',
    },
    select: {
      fontSize: '16px',
      fontWeight: 400,
      height: '40px',
      padding: '0 25px 0 10px',
      border: '1px solid',
      borderColor: 'text',
      borderRadius: '2px',
      backgroundColor: 'transparent',
      appearance: 'none',
      position: 'relative',
    }
  },
  left: {
    display: 'flex',
    flexDirection: ['column', null, 'row'],
    alignItems: 'center',
    mt: [4, null, null, null, null]
  },
  right: {
    display: 'flex',
    flexDirection: ['column', null, 'row'],
    fontSize: 2,
    alignItems: 'center',
    a: {
      transition: '500ms',
      color: 'text',
      textDecoration: 'none'
    },
    'a+a': {
      marginLeft: [null, null, '30px']
    },
  },
};
