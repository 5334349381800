import React from "react"
import { Link, FormattedMessage } from "gatsby-plugin-intl"

const MobileMenu = (props) => {
  const isPartiallyActive = ({ isPartiallyCurrent }) => {
    if (typeof window !== 'undefined') {
      const pathName = window.location.pathname;
      if(pathName.includes('/blog/')){
        return { className: 'header__nav__link header__nav__link--active' };
      }
    }
    return { className: 'header__nav__link' };
  }


  return (
    <nav className={`mobile-menu ${props.menuVisible ? 'mobile-menu--visible' : ''}`}>
      <div className="inner">
        <Link to="/features/" className="header__nav__link" activeClassName="header__nav__link--active">Features</Link>
        <Link to="/blog/loyalty-campaigns/" className="header__nav__link" getProps={isPartiallyActive}>Blog</Link>
        <Link to="/faq/" className="header__nav__link" activeClassName="header__nav__link--active">FAQ</Link>
      </div>
      <div className="container close-button-wrapper">
        <button className="close-button ml-auto" role="button" aria-label="close menu" onClick={() => props.setMenuVisible(false)}>
          <i className="fa fa-times"></i>
        </button>
      </div>
    </nav>
  )
}

export default MobileMenu
